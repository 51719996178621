<template>
  <div>
    <v-container>
      <v-row class="text-center my-5">
        <v-col class="mb-4 mx-auto mt-n4 selectbox" md="8">
          <v-card flat class="card-border">
            <v-card-text class="pa-0">
              <v-row class="mx-0" style="display: flex">
                <v-col
                  class="d-flex"
                  md="12"
                  style="flex-wrap: wrap; justify-content: center"
                >
                  <v-hover
                    v-for="cat in categories"
                    :key="cat.id"
                    class="mr-5 mb-5 child"
                  >
                    <template v-slot="{ hover }">
                      <v-card
                        class="card-color d-flex align-center"
                        min-width="90px"
                        style="height: 110px"
                        @click="
                          selectedCategory = cat.id;
                          getService();
                        "
                        :class="`elevation-${hover ? 16 : 3}`"
                        :color="
                          hover || selectedCategory == cat.id
                            ? 'teal lighten-1'
                            : ''
                        "
                        :to="cat.route"
                        :style="
                          hover || selectedCategory == cat.id
                            ? 'color: white; transform: scale(1.1);'
                            : ''
                        "
                      >
                        <div style="margin-left: auto; margin-right: auto">
                          <v-icon
                            class="ma-2"
                            x-large
                            dark
                            :color="
                              hover || selectedCategory == cat.id
                                ? 'white'
                                : 'primary'
                            "
                          >
                            {{ cat.icon }}
                          </v-icon>
                          <p style="font-weight: 700" class="number-text">{{ cat.text }}</p>
                        </div>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mx-auto mt-n4 selectdefault" md="8">
          <v-card flat class="card-border">
            <v-card-text>
              <v-select
                :items="categories"
                v-model="selectedCategory"
                @change="getService()"
                item-value="id"
                label="Xizmatni tanlang"
                solo
                rounded
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <p>{{ phone }}</p> -->
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      showInfoDialog: false,
      selectedService: null,
      selectedCategory: 1,
      searchTitle: "Umumiy",
      services: [],
      tab: null,
      categories: [
        {
          id: 1,
          icon: "mdi-car-shift-pattern",
          text: "Asosiy",
          route: "/home",
        },
        {
          id: 2,
          icon: "mdi-card-account-details",
          text: "Imtixon",
          route: "/quiz",
        },
        {
          id: 3,
          icon: "mdi-menu",
          text: "Marafon",
          route: "/marafon",
        },
        {
          id: 4,
          icon: "mdi-ticket-confirmation",
          text: "Mavzular",
          route: "/home",
        },
        {
          id: 5,
          icon: "mdi-alert-circle",
          text: "Jarimalar",
          route: "/home",
        },
        {
          id: 6,
          icon: "mdi-lightbulb-alert",
          text: "Xatolarim",
          route: "/home",
        },
      ],
    };
  },
  methods: {
    getList() {
      this.showInfoDialog = true;
    },
    getService() {
      this.selectedService = "";
      this.services = null;
      if (this.selectedCategory == 1) {
        this.services = this.default;
      } else if (this.selectedCategory == 5) {
        this.services = this.yhq;
      } else if (this.selectedCategory == 2) {
        this.services = this.govboj;
      } else if (this.selectedCategory == 3) {
        this.services = this.phone;
      } else if (this.selectedCategory == 4) {
        this.services = this.kommunal;
      } else if (this.selectedCategory == 6) {
        this.services = this.halol;
      }
      this.searchTitle = this.categories.find(
        (o) => o.id == this.selectedCategory
      ).text;
    },
    getItemText(item) {
      return `${item.code}${item.prefix}`;
    },
  },
  mounted() {
    this.services = this.default;
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    },
    screenHeight() {
      return window.innerHeight;
    },
  },
};
</script>
<style scoped>
.bg-body {
  height: 500px;
  background-color: #2196f3;
}
.active-class {
  background-color: white;
}
.card-border {
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: transparent;
}
.card-color {
  /* background-color: #787d83; */
  opacity: 0.9;
}
.child {
  width: 20%;
  box-sizing: border-box;
}
.selectdefault {
  display: none;
}
.largetext {
  font-size: 48px;
  font-weight: 700;
}
.middletext {
  font-size: 24px;
}
.smalltext {
  font-size: 16px;
}
</style>
