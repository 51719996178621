var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{staticClass:"px-4",attrs:{"xs12":"","sm8":"","md5":""}},[(_vm.questions.length > 0 && _vm.userAnswers.filter(v=>v.answer==false).length<3 && _vm.userAnswers.filter(v=>v!=null).length<20 && _vm.timeLeft>0)?_c('div',[_c('div',{staticClass:"d-flex jusftify-content"},[_c('v-btn',{staticClass:"pa-0",attrs:{"text":"","dense":"","small":"","color":"primary","to":"home"}},[_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" Asosiy")],1),_c('v-spacer'),_c('div',{staticStyle:{"color":"red"}},[_vm._v("XATO: "+_vm._s(_vm.userAnswers.filter(v=>v.answer==false).length))]),_c('v-spacer'),_c('div',[_c('h4',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.currentQuestionIndex + 1)+" / 1000")])])],1),_c('hr'),(_vm.currentQuestionIndex < _vm.questions.length)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.currentQuestion.question))]),_c('img',{staticStyle:{"max-width":"100%","margin-bottom":"10px"},attrs:{"src":_vm.getImagePath(_vm.currentQuestion ? _vm.currentQuestion.media.name : 'banner', 2)}}),_vm._l((_vm.currentQuestion.choises),function(choice,index){return _c('div',{key:index,staticClass:"mb-3 pa-1 choice-container",class:_vm.userAnswers.filter(l=>l.index==_vm.currentQuestionIndex).length>0 &&
                _vm.userAnswers.filter(l=>l.index==_vm.currentQuestionIndex)[0].choice === index &&
                choice.answer
                ? 'correct' 
                : _vm.userAnswers.filter(l=>l.index==_vm.currentQuestionIndex).length>0 && 
                _vm.userAnswers.filter(l=>l.index==_vm.currentQuestionIndex)[0].choice === index &&
                !choice.answer
                ? 'incorrect' 
                : (_vm.userAnswers.filter(l=>l.index==_vm.currentQuestionIndex).length>0 &&
                choice.answer
                ? 'correct' 
                : 'default'),staticStyle:{"border":"1px solid var(--v-borderc-base) !important","border-radius":"10px"}},[_c('label',{staticStyle:{"padding-left":"10px","display":"flex","align-items":"center","cursor":"pointer","width":"100%"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"radio","disabled":_vm.userAnswers[_vm.currentQuestionIndex]!=null},domProps:{"value":index},on:{"click":function($event){return _vm.setAnswer(index, choice.answer)}}}),_vm._v(" "+_vm._s(choice.text)+" ")])])})],2):_vm._e(),_c('v-row',{staticClass:"mb-2 mt-2"},[_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.prevQuestion}},[_vm._v("Avvalgi")])],1),_c('v-spacer'),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.nextQuestion}},[_vm._v("Keyingi")])],1)],1),_c('v-fab-transition',[_c('v-btn',{staticClass:"v-btn--floating",attrs:{"color":"success","fab":"","x-small":"","dark":"","bottom":"","left":""},on:{"click":function($event){_vm.showHelpModal=true}}},[_c('v-icon',[_vm._v("mdi-lightbulb")])],1)],1)],1):_vm._e()])],1),_c('v-dialog',{model:{value:(_vm.showHelpModal),callback:function ($$v) {_vm.showHelpModal=$$v},expression:"showHelpModal"}},[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base)"}},[_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(_vm.currentQuestion ? _vm.currentQuestion.description : '')+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.showHelpModal = false}}},[_vm._v(" Yopish ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-img',{staticClass:"zoomed-image",attrs:{"src":_vm.getImagePath(_vm.currentQuestion ? _vm.currentQuestion.media.name : 'banner', 2)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }